import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../config/app.config'

Vue.use(Vuex)

export default {
    state: {
        templates: []
    },
    mutations: {
        setTemplates(state, templates) {
            state.templates = templates
        }
    },
    actions: {
        async createTemplates({ commit, state }, data) {
            try {
                const request = await axios.post(config.API_BASE + '/template/add', data)
                const newObject = [...state.templates, data]
                commit('setTemplates', newObject)
            } catch (e) {
                console.log(e);
                return false
            }
        },

        async deleteTemplateFromDB({ commit, state }, deletedID) {

            try {
                await axios.post(config.API_BASE + '/template/delete/' + deletedID)
                const newObject = state.templates.filter(item => item.id != deletedID)
                commit('setTemplates', newObject)
                return true;
            } catch (e) {
                console.log(e)
            }
        },

        async fetchAllTemplates({ commit }) {
            try {
                const request = await axios.get(config.API_BASE + '/template')
                commit('setTemplates', request.data)
            } catch (e) {
                console.log(e)
            }
        },

        async fetchTemplateById({ commit }, id) {
            try {
                const request = await axios.get(config.API_BASE + '/template/' + id)
                return request.data
            } catch (e) {
                console.log(e)
            }
        },

        async editTemplate({ commit }, data) {
            try {
                await axios.post(config.API_BASE + '/template/update/' + data.id, data)
                return true
            } catch (e) {
                console.log(e)
            }
        }

    },
    getters: {
        getTemplates: (store) => store.templates,
        getTemplateById: store => id => store.templates.find(item => item.id == id)
    }
}
