import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../config/app.config'

Vue.use(Vuex)

export default {
    state: {
        waysByUser: []
    },
    mutations: {
        setWaysList(state, waysList) {
            state.waysByUser = waysList
        }
    },
    actions: {
        async fetchUserInformatings({commit}, user_id) {
            try {
              const fetchedItems = await axios.get(`${config.API_BASE}/informating/${user_id}`)
              commit('setWaysList', fetchedItems.data)
            } catch (e) {
                console.log(e)
            }
        },

        async deleteInformatings({commit, state}, id) {
            try {
                await axios.post(config.API_BASE + '/informating/delete/' + id)
                const newObject = state.waysByUser.filter(item => item.id != id)
                commit('setWaysList', newObject)
                return true;
            } catch (e) {
                console.log(e)
            }
        },

        async createInformationWay({ commit, state }, data) {
            try {
                const newItemId = await axios.post(config.API_BASE + '/informating/add', data)
                console.log(newItemId);
                const newItem = {
                    ...data,
                    id: newItemId.data.id
                }
                const newObject = [...state.waysByUser, newItem]
                commit('setWaysList', newObject)
            } catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
        getWaysByUser: (store) => store.waysByUser
    }
}
