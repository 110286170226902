import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import config from '../config/app.config'

Vue.use(Vuex)

export default {
  state: {
    agregators: []
  },
  mutations: {
    setAgregators(state, items) {
      state.agregators = items
    },
    updateSingleAgrInfo(state, agr) {
      state.agregator = state.agregator.map(item => {
        if(item.id == agr.id){
          return agr
        }
        return item
      })
    }
  },
  actions: {
    //Получение всех аккаунтов
    async fetchAllAgregators({ commit }) {
      try {
        const agrReq = await axios.get(config.API_BASE + '/agregators')
        commit('setAgregators', agrReq.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchAgregatorById({ commit }, id) {
      try {
        const agrReq = await axios.get(config.API_BASE + '/agregators/' + id)
        // console.log(agrReq);
        return agrReq.data
      } catch (e) {
        console.log(e)
      }
    },

    //Delete account
    async deleteAgregator({ commit }, id) {
      try {
        await axios.post(config.API_BASE + '/agregators/delete/' + id)
        return true;
      } catch (e) {
        console.log(e)
      }
    },

    //Update agregator`s data
    async updateAgregator({commit}, agr) {
      try {
        const agrReq = await axios.post(config.API_BASE + '/agregators/update', agr)
        if (!agrReq.error) {
          commit('updateSingleAgrInfo', agr)
          return true
        } else {
          return false
        }
      } catch (e) {
        // console.log(e);
        return false
      }
    },




    //Добавление аккаунта в БД
    async addAgregatorToDB({ }, agregator) {
      try {
        const agrReq = await axios.post(config.API_BASE + '/agregators/add', agregator)
        if (!agrReq.error) {
          return true
        } else {
          return false
        }


      } catch (e) {
        console.log(e);
        return false
      }
    }
  },
  getters: {
    getAllAgregators: (s) => s.agregators
  }
}
