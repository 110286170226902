import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import config from '../config/app.config'

Vue.use(Vuex)

export default {
    state: {
        allTickets: []
    },
    mutations: {
        setAllTickets(state, items) {
            state.allTickets = items
        }
    },
    actions: {
        //Получение всех аккаунтов
        async fetchAllTickets({ commit }) {
            try {
                const accReq = await axios.get(config.API_BASE + '/ticket')
                console.log(accReq);
                commit('setAllTickets', accReq.data)
            } catch (e) {
                console.log(e)
            }
        },

        //Delete ticket
        async deleteTicket({ commit }, id) {
            try {
                await axios.post(config.API_BASE + '/ticket/delete/' + id)
                return true;
            } catch (e) {
                console.log(e)
            }
        },

        //Закроепление тикета за юзером
        async setTicketResp(store, data) {
            try {
                if (data) {
                    await axios.post(config.API_BASE + '/ticket/setResp/', data)
                } else {
                    throw new Error('Data is empty')
                }
                return true;
            } catch (e) {
                console.log(e)
            }
        },

        //Закрытие тикета

        async closeTicket(store, id) {
            try {
                await axios.post(config.API_BASE + '/ticket/close/' + id)
                return true;
            } catch (e) {
                console.log(e)
            }
        }

    },
    getters: {
        getAllTickets: (s) => s.allTickets
    }
}
