import Vue from 'vue'
import Vuex from 'vuex'

import config from '../config/app.config'
import axios from 'axios'



import user from './user'
import accounts from './accounts'
import agregators from './agregators'
import tickets from './tickets'
import informationway from './informationway'
import templates from './templates'
import monetization from './monetization'







Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    async rebootSystem() {
      try {
        await axios.get(config.API_BASE + '/utils/reboot')
      } catch (e) {
        console.log(e);
      }
    }
  },
  modules: {
    user,
    accounts,
    agregators,
    tickets,
    informationway,
    templates ,
    monetization
  }
})
