import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import config from '../config/app.config'

Vue.use(Vuex)

export default {
  state: {
    monetizations: []
  },
  mutations: {
    setMonetizations(state, items) {
        state.monetizations = items
    }   
  },
  actions: { 
      //Получение всех аккаунтов
      async fetchAllMonetization({commit}) {
          try {
             const accReq = await axios.get(config.API_BASE + '/monetization')
             commit('setMonetizations', accReq.data)
          } catch (e) {
              console.log(e)
          }
      },

      async fetchMonetizationById({ commit }, id) {
        try {
          const agrReq = await axios.get(config.API_BASE + '/monetization/' + id)
          return agrReq.data
        } catch (e) {
          console.log(e)
        }
      },

      //Delete account
      async deleteMonetization({commit}, id) {
        try {
          await axios.post(config.API_BASE + '/monetization/delete/' + id)
          return true;
        } catch (e) {
          console.log(e)          
        }
      },

      //Update account
      async toggleMonetizationStatus({commit}, id) {
        try {
          await axios.post(config.API_BASE + '/monetization/togglestatus/' + id)
          return true;
        } catch (e) {
          console.log(e)          
        }
      },


      //Добавление аккаунта в БД
      async addMonetizationToDB({}, account){
        try{
            const accReq = await axios.post(config.API_BASE + '/monetization/add', account)
            if(!accReq.error) {
                return true
            }else{
                return false
            }


        }catch(e){
            console.log(e);
            return false
        }
      } ,

      //Обновление аккаунтов

      async updateMonetizationToDB({}, account) {
        try {
          const agrReq = await axios.post(config.API_BASE + '/monetization/update', account)
          if (!agrReq.error) {
            return true
          } else {
            return false
          }
        } catch (e) {
          // console.log(e);
          return false
        }
      }
  },
  getters: {
    getAllMonetizations: (s) => s.monetizations
  }
}
