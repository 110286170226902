<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Made with
          <i class="mdi mdi-heart text-danger"></i>
          by DM-CODE
        </span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "app-footer"
};
</script>
