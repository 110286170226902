export default {
    API_BASE : process.env.NODE_ENV === "production"
    ? "http://monitoring.tiontele.com:8081"
    : 'http://localhost:8081',

    // API_BASE :  "http://monitoring.tiontele.com:8080"
    // API_BASE :  "http://localhost:8081"

}

//http://monitoring.tiontele.com:8080

//http://monitoring.webcom.mobi:8080