import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import config from '../config/app.config'

Vue.use(Vuex)

export default {
  state: {
    isLogged: localStorage.getItem('WMtoken') ? true : false,
    userData: [],
    authToken: localStorage.getItem('WMtoken') || '',
  },
  mutations: {
    userIsLogin(state) {
      state.isLogged = true
    },
    setUserInfo(state, obj) {
      state.userData = obj
    }
  },
  actions: { 

    //Логин
    async loginAction({dispatch, commit}, userData) {
      try{
        const authRequest = await axios.post(config.API_BASE + '/auth/login', userData )
        console.log(authRequest.data);
        if(authRequest.data.token) {
           localStorage.setItem('WMtoken', authRequest.data.token)
           commit('userIsLogin')
           axios.defaults.headers.common['Authorization'] = authRequest.data.token
           return true;
        } else{
           return false;
        }
      }catch(e) {
          console.log(e)
      }
    },

    //Получение инфо о пользователе
    async fetchInfo({dispatch, commit, state}){
      try{
        const token = state.authToken
        if(token) {
          const userObj = await axios.post(config.API_BASE + '/auth/fetchinfo', {token})

          commit('setUserInfo', userObj.data)
        } else {
          throw new Error('Auth token is empty!')
        }
        
      }catch(e){
          throw e;
      }
    },
  },
  getters: {
    isLogged: (s) => s.isLogged,
    getToken: (s) => s.authToken,
    getInfo: (s) => s.userData,
    getUserID: (s) => s.userData.id
  }
}
