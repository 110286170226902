import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import config from '../config/app.config'

Vue.use(Vuex)

export default {
  state: {
    accounts: []
  },
  mutations: {
    setAccounts(state, items) {
        state.accounts = items
    }   
  },
  actions: { 
      //Получение всех аккаунтов
      async fetchAllAccounts({commit}) {
          try {
             const accReq = await axios.get(config.API_BASE + '/acc')
             commit('setAccounts', accReq.data)
          } catch (e) {
              console.log(e)
          }
      },

      async fetchAccountById({ commit }, id) {
        try {
          const agrReq = await axios.get(config.API_BASE + '/acc/' + id)
          return agrReq.data
        } catch (e) {
          console.log(e)
        }
      },

      //Delete account
      async deleteAccount({commit}, id) {
        try {
          await axios.post(config.API_BASE + '/acc/delete/' + id)
          return true;
        } catch (e) {
          console.log(e)          
        }
      },

      //Update account
      async toggleStatus({commit}, id) {
        try {
          await axios.post(config.API_BASE + '/acc/togglestatus/' + id)
          return true;
        } catch (e) {
          console.log(e)          
        }
      },


      //Добавление аккаунта в БД
      async addAccountToDB({}, account){
        try{
            const accReq = await axios.post(config.API_BASE + '/acc/add', account)
            if(!accReq.error) {
                return true
            }else{
                return false
            }


        }catch(e){
            console.log(e);
            return false
        }
      } ,

      //Обновление аккаунтов

      async updateAccountToDB({}, account) {
        try {
          const agrReq = await axios.post(config.API_BASE + '/acc/update', account)
          if (!agrReq.error) {
            return true
          } else {
            return false
          }
        } catch (e) {
          // console.log(e);
          return false
        }
      }
  },
  getters: {
    getAllAccounts: (s) => s.accounts
  }
}
